import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    helper = new JwtHelperService();
    token = localStorage.getItem('_token')
    decodedToken = this.helper.decodeToken(this.token);
    expirationDate = this.helper.getTokenExpirationDate(this.token);
    isExpired = this.helper.isTokenExpired(this.token);
    subscription: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toast: ToastrService, private titleService: Title) {


        // this.router.events.pipe(
        //     filter(event => event instanceof NavigationEnd),
        //     map(() => {
        //         let child = this.activatedRoute.firstChild;
        //         while (child) {
        //             if (child.firstChild) {
        //                 child = child.firstChild
        //             } else if (child.snapshot.data && child.snapshot.data['title']) {
        //                 return child.snapshot.data['title'];
        //             }
        //             else {
        //                 return null
        //             }
        //         }
        //         return null
        //     })
        // ).subscribe((data: any) => {
        //     if (data) {
        //         this.titleService.setTitle(data +'-ID Jardin')
        //     }
        // })
    }
    ngOnInit() {
        if (this.token) {
            if (this.isExpired == true) {
                this.toast.error("Token Expire Veuillez vous connecter agian");
                localStorage.clear();
                this.router.navigate(['/pages/login'])
                return false
            }
        }
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}